import React, { useEffect, useState } from 'react';
import './ScrollUpButton.scss';

const ScrollUpButton = () => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 500) {
                setVisible(true);
            } else {
                setVisible(false);
            }
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div
            className={`scroll-up-btn ${visible ? 'show' : ''}`}
            onClick={scrollToTop}
            role="button"
            aria-label="Scroll to top"
            tabIndex={0} // Permet de rendre le composant cliquable au clavier
        >
            <i className="fas fa-arrow-up" aria-hidden="true"></i>
        </div>
    );
};

export default ScrollUpButton;
