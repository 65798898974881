// Importation de React
import React from 'react';
// Importation des styles spécifiques pour ce composant
import './Footer.scss';
// Importation du composant SocialIcons
import SocialIcons from '../SocialIcons/SocialIcons';

// Composant fonctionnel Footer
const Footer = () => {
    return (
        // Balise <footer> pour le pied de page
        <footer>
            {/* Composant SocialIcons pour afficher les icônes sociales */}
            <SocialIcons />
            
            {/* Paragraphe pour les droits d'auteur et le lien vers le site */}
            <p>
                &copy; 2024 
                <a href="https://www.rivelon.fr/" target="_blank" rel="noopener noreferrer"> Mo</a> 
                | All Rights Reserved |
            </p>
        </footer>
    );
};

// Exportation du composant Footer
export default Footer;
