// Importation de React et ReactDOM
import React from 'react';
import ReactDOM from 'react-dom/client';
// Importation des styles globaux de l'application
import './index.scss'; // Styles SCSS spécifiques à l'index
import './styles/global.scss'; // CSS global de l'application

// Importation du composant Routes qui gère les routes de l'application
import Routes from './App/Routes';

// Importation de la fonction reportWebVitals pour mesurer les performances
import reportWebVitals from './reportWebVitals';

// Création de la racine ReactDOM pour le rendu de l'application dans l'élément root
const root = ReactDOM.createRoot(document.getElementById('root'));

// Rendu du composant principal de l'application dans la racine
root.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>,
);

// Mesure des performances de l'application en utilisant la fonction reportWebVitals
reportWebVitals();
