// Importations nécessaires depuis React et Helmet
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// Importation des composants TypingEffect et ScrollDownButton
import TypingEffect from '../../components/TypingEffect/TypingEffect';
import ScrollDownButton from '../../components/ScrollDownButton/ScrollDownButton';
// Importation des données meta et structuredData
import { meta, structuredData } from '../../datas';
// Importation des styles spécifiques pour ce composant
import './Home.scss';

// Définition du composant fonctionnel Home
const Home = () => {
    return (
        <HelmetProvider>
            {/* Fragment React pour contenir les éléments du composant */}
            <>
                {/* Utilisation de Helmet pour définir les balises <head> */}
                <Helmet>
                    <title>{meta.title} - Home</title>
                    <meta
                        name="description"
                        content={`Explore ${meta.title}'s home`}
                    />
                    <meta name="keywords" content={meta.keywords} />
                    <meta name="robots" content={meta.robots} />
                    {/* Balises Open Graph */}
                    <meta property="og:title" content={meta.ogTitle} />
                    <meta
                        property="og:description"
                        content={meta.ogDescription}
                    />
                    <meta property="og:image" content={meta.ogImage} />
                    <meta property="og:url" content={meta.ogUrl} />
                    <meta property="og:type" content={meta.ogType} />
                    <meta property="og:locale" content={meta.ogLocale} />
                    {/* Balises Twitter */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={meta.title} />
                    <meta
                        name="twitter:description"
                        content={meta.description}
                    />
                    <meta name="twitter:image" content={meta.ogImage} />
                    <link rel="canonical" href={meta.ogUrl} />
                    {/* Données structurées JSON-LD */}
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                </Helmet>
                {/* Section "home" avec un identifiant pour l'ancrage */}
                <section className="home" id="home">
                    <div className="max-width">
                        <div className="home-content">
                            {/* Texte statique présentant le nom */}
                            <div className="text-1">Hello, I am</div>
                            <div className="text-2">Morgan</div>
                            {/* Texte dynamique avec l'effet de frappe */}
                            <div className="text-3">
                                And I&apos;m a{' '}
                                <TypingEffect
                                    strings={[
                                        'Web Developer',
                                        'Technician',
                                        'Designer',
                                    ]}
                                    className="typing"
                                />
                            </div>
                        </div>
                        {/* Bouton de défilement vers le bas */}
                        <ScrollDownButton />
                    </div>
                </section>
            </>
        </HelmetProvider>
    );
};

// Exportation du composant pour l'utiliser dans d'autres parties de l'application
export default Home;
