// Importation des modules nécessaires depuis React
import React from 'react';
// Importation des styles spécifiques pour ce composant
import './SocialIcons.scss';
// Importation des icônes de réseaux sociaux depuis react-icons/fa
import {
  FaGithub,
  FaTwitter,
  FaFacebookF,
  FaLinkedin,
  FaYoutube,
  FaTwitch,
  FaInstagram,
} from 'react-icons/fa';
// Importation des profils sociaux depuis un fichier de données externe
import { socialprofils } from '../../datas';

// Définition du composant fonctionnel SocialIcons
const SocialIcons = () => {
  return (
    <div className="stick_icons">
      <div className="stick_follow_icon">
        <ul>
          {socialprofils.twitter && (
            <li>
              <a
                href={socialprofils.twitter}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
              >
                <FaTwitter />
              </a>
            </li>
          )}
          {socialprofils.github && (
            <li>
              <a
                href={socialprofils.github}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="GitHub"
              >
                <FaGithub />
              </a>
            </li>
          )}
          {socialprofils.facebook && (
            <li>
              <a
                href={socialprofils.facebook}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
              >
                <FaFacebookF />
              </a>
            </li>
          )}
          {socialprofils.linkedin && (
            <li>
              <a
                href={socialprofils.linkedin}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
              >
                <FaLinkedin />
              </a>
            </li>
          )}
          {socialprofils.youtube && (
            <li>
              <a
                href={socialprofils.youtube}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="YouTube"
              >
                <FaYoutube />
              </a>
            </li>
          )}
          {socialprofils.twitch && (
            <li>
              <a
                href={socialprofils.twitch}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitch"
              >
                <FaTwitch />
              </a>
            </li>
          )}
          {socialprofils.instagram && (
            <li>
              <a
                href={socialprofils.instagram}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
              >
                <FaInstagram />
              </a>
            </li>
          )}
        </ul>
        <p>Follow Me</p>
      </div>
    </div>
  );
};

// Exportation du composant pour l'utiliser dans d'autres parties de l'application
export default SocialIcons;
