// Importation des modules nécessaires depuis React
import React, { useEffect, useState } from 'react';
// Importation des styles spécifiques pour ce composant
import './ScrollDownButton.scss';

// Définition du composant fonctionnel ScrollDownButton
const ScrollDownButton = () => {
    // Gestion de l'état pour contrôler la visibilité du bouton
    const [visible, setVisible] = useState(true);

    // Utilisation d'un effet pour ajouter un écouteur de défilement à la fenêtre
    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY < 50) {
                setVisible(true); // Afficher le bouton si le défilement est inférieur à 50px
            } else {
                setVisible(false); // Cacher le bouton sinon
            }
        };

        window.addEventListener('scroll', toggleVisibility); // Ajouter l'écouteur

        // Nettoyage de l'écouteur lorsque le composant est démonté
        return () => {
            window.removeEventListener('scroll', toggleVisibility);
        };
    }, []);

    // Fonction pour faire défiler vers la section 'news'
    const scrollToAbout = () => {
        const newsSection = document.getElementById('news');
        if (newsSection) {
            newsSection.scrollIntoView({ behavior: 'smooth' }); // Défilement fluide vers la section 'news'
        }
    };

    return (
        <div
            className={`scroll-down-btn ${visible ? 'show' : ''}`}
            onClick={scrollToAbout}
            role="button"
            aria-label="Scroll to news section"
            tabIndex={0} // Permet de rendre le composant cliquable au clavier
        >
            {visible && <div className="scroll-down-text">NEWS</div>}
            <i className="fas fa-arrow-down" aria-hidden="true"></i>
        </div>
    );
};

// Exportation du composant pour l'utiliser dans d'autres parties de l'application
export default ScrollDownButton;
