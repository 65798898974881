// Importation des modules nécessaires depuis React
import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// Importation des données de services depuis le fichier de données
import { meta, servicesData, structuredData } from '../../datas';
// Importation des styles spécifiques pour ce composant
import './Services.scss';

const Services = () => {
    // Gestion de l'état pour savoir quel service est survolé
    const [hoveredService, setHoveredService] = useState(null);

    return (
        <HelmetProvider>
            {/* Fragment React pour contenir les éléments du composant */}
            <>
                {/* Utilisation de Helmet pour définir les balises <head> */}
                <Helmet>
                    <title>{meta.title} - Services</title>
                    <meta
                        name="description"
                        content={`Explore ${meta.title}'s services`}
                    />
                    <meta name="keywords" content={meta.keywords} />
                    <meta name="robots" content={meta.robots} />
                    {/* Balises Open Graph */}
                    <meta property="og:title" content={meta.ogTitle} />
                    <meta
                        property="og:description"
                        content={meta.ogDescription}
                    />
                    <meta property="og:image" content={meta.ogImage} />
                    <meta property="og:url" content={meta.ogUrl} />
                    <meta property="og:type" content={meta.ogType} />
                    <meta property="og:locale" content={meta.ogLocale} />
                    {/* Balises Twitter */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={meta.title} />
                    <meta
                        name="twitter:description"
                        content={meta.description}
                    />
                    <meta name="twitter:image" content={meta.ogImage} />
                    <link rel="canonical" href={meta.ogUrl} />
                    {/* Données structurées JSON-LD */}
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                </Helmet>
                {/* Section "services" avec un identifiant pour l'ancrage */}
                <section className="services" id="services">
                    <div className="max-width">
                        {/* Titre de la section */}
                        <h2 className="title">Services</h2>
                        <div className="serv-content"></div>
                    </div>

                    {/* Conteneur des services */}
                    <div className="container">
                        {servicesData.map((service) => (
                            // Boîte de chaque service avec des événements pour la gestion du survol
                            <div
                                key={service.id}
                                className="box gradient-background"
                                onMouseEnter={() =>
                                    setHoveredService(service.id)
                                }
                                onMouseLeave={() => setHoveredService(null)}
                            >
                                {/* Icône du service avec des styles dynamiques en fonction du survol */}
                                <span
                                    id={`service-${service.id}`}
                                    className={
                                        hoveredService === service.id
                                            ? 'animate'
                                            : ''
                                    }
                                    style={
                                        hoveredService === service.id
                                            ? {
                                                  bottom: '90px',
                                                  right: '35px',
                                                  width: '70px',
                                                  height: '70px',
                                                  opacity: 1,
                                                  backgroundImage: `url(${service.icon})`,
                                                  backgroundSize: '70px',
                                                  backgroundPosition: 'center',
                                                  zIndex: 51,
                                                  filter: 'brightness(0) invert(1)',
                                              }
                                            : {}
                                    }
                                ></span>

                                {/* Contenu du service */}
                                <div className="content">
                                    <h2>{service.title}</h2>
                                    <p>{service.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </section>
            </>
        </HelmetProvider>
    );
};

// Exportation du composant pour l'utiliser dans d'autres parties de l'application
export default Services;
