// Importation des modules nécessaires depuis React et react-router-dom
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// Importation des styles spécifiques pour ce composant
import './Navbar.scss';

// Définition du composant fonctionnel Navbar
const Navbar = () => {
    // Gestion de l'état pour contrôler l'ouverture du menu et l'animation de fermeture
    const [isOpen, setIsOpen] = useState(false);
    const [closing, setClosing] = useState(false);
    const location = useLocation(); // Récupère l'emplacement actuel de la page

    // Fonction pour basculer l'état du menu
    const toggleMenu = () => {
        if (isOpen) {
            setClosing(true);
            setTimeout(() => {
                setIsOpen(false);
                setClosing(false);
            }, 500); // Durée de l'animation de fermeture en millisecondes
        } else {
            setIsOpen(true);
        }
    };

    // Fonction pour ajouter une classe active au lien correspondant à la page actuelle
    const getActiveClass = (path) => {
        return location.pathname === path ? 'active' : '';
    };

    // Utiliser useEffect pour faire défiler vers le haut lors du changement d'emplacement
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <nav className={`navbar fixed ${isOpen ? 'menu-open' : ''}`}>
            <div className="max-width">
                {/* Lien vers la page d'accueil */}
                <Link to="/">
                    <p className="navbar-logo"> h.OM.e </p>
                </Link>
                {/* Icône du menu pour les écrans mobiles */}
                <div className={`menu-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>
                {/* Menu de navigation */}
                <ul className={`menu ${isOpen ? 'open' : ''} ${closing ? 'close' : ''}`}>
                    <li>
                        <Link
                            to="/"
                            className={`menu-btn ${getActiveClass('/')}`}
                            onClick={toggleMenu}
                        >
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/About"
                            className={`menu-btn ${getActiveClass('/About')}`}
                            onClick={toggleMenu}
                        >
                            About
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/Skills"
                            className={`menu-btn ${getActiveClass('/Skills')}`}
                            onClick={toggleMenu}
                        >
                            Skills
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/Services"
                            className={`menu-btn ${getActiveClass('/Services')}`}
                            onClick={toggleMenu}
                        >
                            Services
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/Projects"
                            className={`menu-btn ${getActiveClass('/Projects')}`}
                            onClick={toggleMenu}
                        >
                            Projects
                        </Link>
                    </li>
                    <li>
                        <Link
                            to="/Contact"
                            className={`menu-btn ${getActiveClass('/Contact')}`}
                            onClick={toggleMenu}
                        >
                            Contact
                        </Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

// Exportation du composant pour l'utiliser dans d'autres parties de l'application
export default Navbar;
