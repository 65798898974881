// Importations nécessaires depuis React et Helmet
import React, { useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import emailjs from 'emailjs-com'; // Import EmailJS
// Importation des données de meta
import { meta } from '../../datas'; 
// Importation des styles spécifiques pour ce composant
import './Contact.scss';

// Définition du composant fonctionnel Contact
const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const [notification, setNotification] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs
            .sendForm(
                'service_ynotj7p', // Remplacez par votre Service ID EmailJS
                'template_ci0steo', // Remplacez par votre Template ID EmailJS
                e.target,
                '_5gByM_Tt1C2tIboU', // Remplacez par votre User ID EmailJS
            )
            .then(
                (result) => {
                    console.log(result.text);
                    setNotification('Message sent successfully!');
                    // Vider le formulaire après envoi
                    setFormData({
                        name: '',
                        email: '',
                        subject: '',
                        message: '',
                    });
                },
                (error) => {
                    console.log(error.text);
                    setNotification('An error occurred, please try again.');
                },
            );
    };

    return (
        <HelmetProvider>
            {/* Fragment React pour contenir les éléments du composant */}
            <>
                {/* Utilisation de Helmet pour définir les balises <head> */}
                <Helmet>
                    <title>{meta.title} - Contact</title>
                    <meta
                        name="description"
                        content={`Explore ${meta.title}'s contact`}
                    />
                    <meta name="keywords" content={meta.keywords} />
                    <meta name="robots" content={meta.robots} />
                    {/* Balises Open Graph */}
                    <meta property="og:title" content={meta.ogTitle} />
                    <meta
                        property="og:description"
                        content={meta.ogDescription}
                    />
                    <meta property="og:image" content={meta.ogImage} />
                    <meta property="og:url" content={meta.ogUrl} />
                    <meta property="og:type" content={meta.ogType} />
                    <meta property="og:locale" content={meta.ogLocale} />
                    {/* Balises Twitter */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={meta.title} />
                    <meta
                        name="twitter:description"
                        content={meta.description}
                    />
                    <meta name="twitter:image" content={meta.ogImage} />
                    <link rel="canonical" href={meta.ogUrl} />
                </Helmet>
                {/* Section "contact" avec un identifiant pour l'ancrage */}
                <section className="contact" id="contact">
                    <div className="max-width">
                        <h2 className="title">Contact me</h2>
                        <div className="contact-content">
                            {/* Colonne gauche contenant les informations de contact */}
                            <div className="column left">
                                <div className="text">Get in Touch</div>
                                <div className="icons">
                                    <div className="row">
                                        <i
                                            className="fas fa-user"
                                            aria-hidden="true"
                                        ></i>
                                        <div className="info">
                                            <div className="head">Name</div>
                                            <div className="sub-title">
                                                Morgan
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <i
                                            className="fas fa-map-marker-alt"
                                            aria-hidden="true"
                                        ></i>
                                        <div className="info">
                                            <div className="head">Address</div>
                                            <div className="sub-title">
                                                Lyon, France
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <i
                                            className="fas fa-envelope"
                                            aria-hidden="true"
                                        ></i>
                                        <div className="info">
                                            <div className="head">Email</div>
                                            <div className="sub-title">
                                                morganrivelon@gmail.com
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Colonne droite contenant le formulaire de contact */}
                            <div className="column right">
                                <div className="text">Message me</div>
                                {notification && (
                                    <div className="notification">
                                        {notification}
                                    </div>
                                )}
                                <form onSubmit={handleSubmit}>
                                    <div className="fields">
                                        <div className="field name">
                                            <label
                                                htmlFor="contact-name"
                                                className="visually-hidden"
                                            >
                                                Name
                                            </label>
                                            <input
                                                type="text"
                                                id="contact-name"
                                                name="name"
                                                placeholder="Name"
                                                required
                                                value={formData.name}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="field email">
                                            <label
                                                htmlFor="contact-email"
                                                className="visually-hidden"
                                            >
                                                Email
                                            </label>
                                            <input
                                                type="email"
                                                id="contact-email"
                                                name="email"
                                                placeholder="Email"
                                                required
                                                value={formData.email}
                                                onChange={handleChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label
                                            htmlFor="contact-subject"
                                            className="visually-hidden"
                                        >
                                            Subject
                                        </label>
                                        <input
                                            type="text"
                                            id="contact-subject"
                                            name="subject"
                                            placeholder="Subject"
                                            required
                                            value={formData.subject}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="field textarea">
                                        <label
                                            htmlFor="contact-message"
                                            className="visually-hidden"
                                        >
                                            Message
                                        </label>
                                        <textarea
                                            id="contact-message"
                                            name="message"
                                            cols="30"
                                            rows="10"
                                            placeholder="Message.."
                                            required
                                            value={formData.message}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                    <div className="button-area">
                                        <button type="submit">
                                            Send message
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </HelmetProvider>
    );
};

// Exportation du composant pour l'utiliser dans d'autres parties de l'application
export default Contact;
