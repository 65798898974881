import { Icon } from '@iconify/react';
import waveIcon from '../src/images/icon-wave.png'; // Importation de l'image wave
import iconDesign from '../src/images/icon_design.png';
import iconDevelopment from '../src/images/icon_development.png';
import iconSEO from '../src/images/icon_seo.png';
import iconVideo from '../src/images/icon_video.png';
import iconAudio from '../src/images/icon_audio.png';
import projectRiiturii from '../src/images/projects-riiturii.jpg';
import projectGrimoire from '../src/images/projects-monvieuxgrimoire.jpg';
import projectBooki from '../src/images/projects-booki.jpg';
import projectNina from '../src/images/projects-ninacarducci.jpg';
import projectKasa from '../src/images/projects-kasa.jpg';
import projectSophie from '../src/images/projects-sophiebuel.jpg';
import imgMetaMo from './images/profil-picture-mo.jpg';

// META
export const meta = {
    title: 'Morgan Rivelon Web Developper',
    description:
        'I am Morgan web developper, currently working in Lyon France, open to work worldwide',
    keywords:
        'HTML5, CSS3, JavaScript, React, Node.js, Git, SEO, Sass, Styled Components, Bootstrap, Lighthouse, GitHub, web developer Lyon, web developer Beaujolais, web developer for hire, web developer France, react developer Lyon France, react developer Beaujolais, remote web developer, web design, front-end developer, front-end development, full-stack developer, back-end developer, website creation, website creation Beaujolais, web design and development, SEO optimization, video integration, sound design, audio design, portfolio web developer, website for artists, website for businesses, web development for startups, creative web developer, user experience design, responsive design, web accessibility, best development practices, HTML5 developer,CSS3 developer, JavaScript developer, React developer, Node.js developer, Git developer, GitHub developer, SEO for web developers, web accessibility developer, junior web developer, web integrator, développeur web, intégrateur web',
    robots: 'index, follow',
    ogTitle: 'Morgan Rivelon - Web-Developper - Lyon',
    ogDescription:
        'From Sound-Engineer to Web Developer, bridging the gap between Art and Code.',
    ogImage: imgMetaMo,
    ogUrl: 'https://www.rivelon.fr/',
    ogType: 'website',
    ogLocale: 'fr_FR',
};

// SHEMA.ORG Données structurées JSON-LD
export const structuredData = {
    '@context': 'https://schema.org',
    '@type': ['Person', 'ProfessionalService'],
    name: 'Morgan Rivelon',
    image: imgMetaMo,
    url: 'https://www.rivelon.fr',
    address: {
        '@type': 'PostalAddress',
        addressLocality: 'Lyon',
        addressCountry: 'FR',
    },
    sameAs: [
        'https://www.linkedin.com/in/morgan-rivelon-a8b723310/',
        'https://github.com/Mogavartn/',
    ],
};

// NEWS
export const dataNews = {
    title: 'a bit about my self',
    news: 'From Sound-Engineer to Web Developer, bridging the gap between Art and Code.',
};
// ABOUT
export const dataAbout = {
    title: 'a bit about my self',
    aboutme:
        'Fueled by a lifelong passion for music and technology, I evolved from sound engineering to web development.  I combine creativity with technical expertise to craft user experiences that are both beautiful and intuitive. My client-centric collaboration skills ensure I can lead a development project from concept to completion, while my knowledge of audio and video allows me to integrate multimedia seamlessly.',
};

// DEVELOPMENT SKILLS
export const skillData = [
    {
        id: 1,
        skill: <Icon icon="mdi:language-html5" className="skills__icon" />,
        name: 'HTML5',
    },
    {
        id: 2,
        skill: <Icon icon="ion:logo-css3" className="skills__icon" />,
        name: 'CSS3',
    },
    {
        id: 3,
        skill: <Icon icon="mdi:sass" className="skills__icon" />,
        name: 'Sass',
    },
    {
        id: 4,
        skill: (
            <Icon icon="file-icons:styledcomponents" className="skills__icon" />
        ),
        name: 'Styled Components',
    },
    {
        id: 5,
        skill: <Icon icon="fa6-brands:js" className="skills__icon" />,
        name: 'JavaScript',
    },
    {
        id: 6,
        skill: <Icon icon="mdi:react" className="skills__icon" />,
        name: 'React',
    },
    {
        id: 7,
        skill: <Icon icon="ri:bootstrap-fill" className="skills__icon" />,
        name: 'Bootstrap',
    },
    {
        id: 8,
        skill: <Icon icon="mdi:nodejs" className="skills__icon" />,
        name: 'Node.js',
    },
    {
        id: 9,
        skill: <Icon icon="bi:git" className="skills__icon" />,
        name: 'Git',
    },
    {
        id: 10,
        skill: (
            <Icon icon="fa6-brands:square-github" className="skills__icon" />
        ),
        name: 'GitHub',
    },
    {
        id: 11,
        skill: <Icon icon="simple-icons:lighthouse" className="skills__icon" />,
        name: 'Lighthouse',
    },
    {
        id: 12,
        skill: (
            <span className="wave-icons">
                <img src={waveIcon} alt="Wave Icon" className="skills__icon" />
            </span>
        ),
        name: 'Wave Evolution Tool',
    },
];

// OTHER SKILLS
export const otherSkillData = [
    {
        id: 1,
        skill: (
            <Icon icon="simple-icons:adobephotoshop" className="skills__icon" />
        ),
        name: 'Adobe Photoshop',
    },
    {
        id: 2,
        skill: (
            <Icon
                icon="simple-icons:adobepremierepro"
                className="skills__icon"
            />
        ),
        name: 'Adobe Premiere Pro',
    },
    {
        id: 3,
        skill: <Icon icon="simple-icons:protools" className="skills__icon" />,
        name: 'Avid Pro Tools',
    },
    {
        id: 4,
        skill: <Icon icon="simple-icons:wwise" className="skills__icon" />,
        name: 'Audiokinetic Wwise',
    },
];

// SERVICES
export const servicesData = [
    {
        id: 1,
        title: 'Designer',
        description:
            'I bring fresh, innovative ideas to web design, creating modern and impactful visuals.',
        icon: iconDesign,
    },
    {
        id: 2,
        title: 'Development',
        description:
            'I love to transform ideas into impactful digital development solutions.',
        icon: iconDevelopment,
    },
    {
        id: 3,
        title: 'SEO',
        description:
            'I enhance websites with SEO strategies to improve visibility and ranking on Google search results.',
        icon: iconSEO,
    },
    {
        id: 4,
        title: 'Video Editing',
        description:
            'In today\'s digital landscape, video is essential for engaging users and enhancing any online presence.',
        icon: iconVideo,
    },
    {
        id: 5,
        title: 'Sound Design',
        description:
            'Just as crucial is sound design, which brings videos to life with compelling audio and music.',
        icon: iconAudio,
    },
    {
        id: 6,
        title: 'Image Editing',
        description:
            'No projects complete without compelling visuals. I offer professional image editing to elevate your content.',
        icon: iconDesign,
    },
];

// PROJECTS
export const projectsData = [
    {
        id: 1,
        title: 'Booki',
        description:
            'Integration of a responsive design prototype into a functional website using HTML and CSS',
        url: 'https://oc-booki-one.vercel.app',
        github: 'https://github.com/Mogavartn/OC-BOOKI',
        image: projectBooki,
        width: 355,
        height: 205,
    },
    {
        id: 2,
        title: 'Sophie Buel Architecte',
        description:
            'Developement of an interactive and dynamic web page using JavaScript',
        url: 'https://oc-portfolio-architecte-sophie-bluel.vercel.app',
        github: 'https://github.com/Mogavartn/OC-Portfolio-architecte-sophie-bluel',
        image: projectSophie,
        width: 355,
        height: 205,
    },
    {
        id: 3,
        title: 'Nina Carducci Photographe',
        description:
            'Enhancement of a website performance, visibility, and user experience through debugging, SEO optimization, and accessibility measures',
        url: 'https://oc-nina-carducci-liard.vercel.app',
        github: 'https://github.com/Mogavartn/OC-nina-carducci',
        image: projectNina,
        width: 355,
        height: 205,
    },
    {
        id: 4,
        title: 'Kasa',
        description: 'Build of a real estate rental web app with React',
        url: 'https://oc-kasa-ecru.vercel.app',
        github: 'https://github.com/Mogavartn/oc-kasa',
        image: projectKasa,
        width: 355,
        height: 205,
    },
    {
        id: 5,
        title: 'Mon Vieux Grimoire',
        description:
            'Development of a secure and performant book management application with authentication, image management, rating, and code optimization features.',
        url: 'https://oc-monvieuxgrimoire.vercel.app',
        github: 'https://github.com/Mogavartn/oc-monvieuxgrimoire',
        image: projectGrimoire,
        width: 355,
        height: 205,
    },
    {
        id: 6,
        title: 'Riiturii Music',
        description:
            'Build, design and development of the entire website of the artist Riiturii with React',
        url: 'https://www.riiturii-music.fr',
        github: 'https://github.com/Mogavartn/riiturii-music.fr_02',
        image: projectRiiturii,
        width: 355,
        height: 205,
    },
];

export const socialprofils = {
    github: 'https://github.com/Mogavartn/',
    //facebook: "https://facebook.com",
    linkedin: 'https://www.linkedin.com/in/morgan-rivelon-a8b723310/',
    //twitter: "https://twitter.com",
    // youtube: 'https://www.youtube.com/@riituriimusic3436',
    instagram: 'https://www.instagram.com/mo_rituri',
};
