// Importation des modules nécessaires depuis React
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// Importation de l'image de profil
import profilePicture from '../../images/profil-picture-mo.jpg';
// Importation des données de la section "About"
import { meta, dataAbout } from '../../datas';
// Importation des styles spécifiques pour ce composant
import './About.scss';

// Définition du composant fonctionnel About
const About = () => {
    return (
        <HelmetProvider>
            {/* Fragment React pour contenir les éléments du composant */}
            <>
                {/* Utilisation de Helmet pour définir les balises <head> */}
                <Helmet>
                    <title>{meta.title} - About</title>
                    <meta
                        name="description"
                        content={`Explore ${meta.title}'s about`}
                    />
                    <meta name="keywords" content={meta.keywords} />
                    <meta name="robots" content={meta.robots} />
                    {/* Balises Open Graph */}
                    <meta property="og:title" content={meta.ogTitle} />
                    <meta
                        property="og:description"
                        content={meta.ogDescription}
                    />
                    <meta property="og:image" content={meta.ogImage} />
                    <meta property="og:url" content={meta.ogUrl} />
                    <meta property="og:type" content={meta.ogType} />
                    <meta property="og:locale" content={meta.ogLocale} />
                    {/* Balises Twitter */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={meta.title} />
                    <meta
                        name="twitter:description"
                        content={meta.description}
                    />
                    <meta name="twitter:image" content={meta.ogImage} />
                    <link rel="canonical" href={meta.ogUrl} />
                </Helmet>

                {/* Section "about" avec un identifiant pour l'ancrage */}
                <section className="about" id="about">
                    <div className="max-width">
                        <h2 className="title">About me</h2>
                        <div className="underLine"></div>
                        <div className="about-content">
                            {/* Colonne gauche contenant l'image de profil */}
                            <div className="column left">
                                <img
                                    src={profilePicture}
                                    alt="Profile picture of Mo"
                                    loading="lazy"
                                />
                            </div>
                            {/* Colonne droite contenant les informations personnelles */}
                            <div className="column right">
                                <h3>{dataAbout.title}</h3>
                                <p>{dataAbout.aboutme}</p>
                                <a
                                    href="/CV Morgan WebDevSite.pdf"
                                    download="CV Morgan WebDevSite.pdf"
                                    aria-label="Download CV"
                                >
                                    Download CV
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        </HelmetProvider>
    );
};

// Exportation du composant pour l'utiliser dans d'autres parties de l'application
export default About;
