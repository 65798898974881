// Importation de React et des hooks
import React, { useState, useEffect } from 'react';
// Importation de la bibliothèque de carrousel
import Slider from 'react-slick';
// Importation des styles pour le carrousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// Importation des données de projets
import { projectsData } from '../../datas';
// Importation des styles spécifiques pour ce composant
import './Carousel.scss';
// Importation du composant Modal
import Modal from '../Modal/Modal';

// Composant principal du carrousel
const Carousel = () => {
  // État pour contrôler l'affichage du modal
  const [showModal, setShowModal] = useState(false);

  // État pour suivre le projet sélectionné
  const [selectedProject, setSelectedProject] = useState(null);

  // Configuration des paramètres du carrousel
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    dots: false, // Masquer les points par défaut
    arrows: false, // Masquer les flèches par défaut
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  // Fonction pour ouvrir le modal et passer les données du projet sélectionné
  const openModal = (project) => {
    setSelectedProject(project);
    setShowModal(true);
  };

  // Fonction pour fermer le modal
  const closeModal = () => {
    setShowModal(false);
    setSelectedProject(null);
  };

  // Ajout de tabindex="-1" aux liens dans les éléments clonés après le montage du composant
  useEffect(() => {
    const clonedSlides = document.querySelectorAll('.slick-slide.slick-cloned a');
    clonedSlides.forEach(slide => {
      slide.setAttribute('tabindex', '-1');
    });
  }, []); // Exécuter une seule fois après le montage

  // Obtention des 3 derniers projets
  const latestProjects = projectsData.slice(-3).reverse();

  return (
    <div className="carousel-container">
      <Slider {...settings}>
        {latestProjects.map((project) => (
          <div key={project.id} className="item" onClick={() => openModal(project)}>
            <a href={project.url} target="_blank" rel="noopener noreferrer">
              <img src={project.image} alt={project.title} loading="lazy" />
              <div className="project-info">
                <h3>{project.title}</h3>
              </div>
            </a>
          </div>
        ))}
      </Slider>

      {/* Modal affichant les détails du projet */}
      <Modal
        show={showModal}
        onClose={closeModal}
        title={selectedProject ? selectedProject.title : ''}
        description={selectedProject ? selectedProject.description : ''}
        url={selectedProject ? selectedProject.url : ''}
        github={selectedProject ? selectedProject.github : ''}
        image={selectedProject ? selectedProject.image : ''}
      />
    </div>
  );
};

// Exportation du composant Carousel
export default Carousel;
