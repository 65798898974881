// Importation de React et des composants de React Router
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Importation des composants principaux de l'application
import App from '../App/App';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import ScrollUpButton from '../components/ScrollUpButton/ScrollUpButton';

// Importation des composants de pages
import Home from '../pages/Home/Home';
import About from '../pages/About/About';
import Skills from '../pages/Skills/Skills';
import Services from '../pages/Services/Services';
import Projects from '../pages/Projects/Projects';
import Contact from '../pages/Contact/Contact';
import Carousel from '../components/Carousel/Carousel';

// Composant principal pour la gestion des routes
const MyRoutes = () => {
  return (
    // Utilisation de Router pour définir le routage de l'application
    <Router>
      {/* Affichage de la barre de navigation */}
      <Navbar />
      {/* Définition des routes */}
      <Routes>
        {/* Route pour la page d'accueil */}
        <Route exact path="/" element={<App />} />
        {/* Route pour le composant Carousel */}
        <Route path="/Carousel" element={<Carousel />} />
        {/* Route pour la page Home */}
        <Route path="/Home" element={<Home />} />
        {/* Route pour la page About */}
        <Route path="/About" element={<About />} />
        {/* Route pour la page Skills */}
        <Route path="/Skills" element={<Skills />} />
        {/* Route pour la page Services */}
        <Route path="/Services" element={<Services />} />
        {/* Route pour la page Projects */}
        <Route path="/Projects" element={<Projects />} />
        {/* Route pour la page Contact */}
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      {/* Affichage du pied de page */}
      <Footer />
      {/* Bouton de retour en haut de la page */}
      <ScrollUpButton />
    </Router>
  );
};

// Exportation du composant MyRoutes
export default MyRoutes;
