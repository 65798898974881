// Importation de React et des hooks useRef et useEffect
import React, { useRef, useEffect } from 'react';
// Importation des composants et modules de react-bootstrap
import { Modal as BootstrapModal, Button } from 'react-bootstrap';
// Importation des icônes FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
// Importation des styles spécifiques pour ce composant
import './Modal.scss';

// Composant fonctionnel Modal
const Modal = ({ show, onClose, title, description, url, github, image }) => {
  // Référence pour le modal
  const modalRef = useRef(null);

  // Effet pour gérer le clic en dehors du modal pour le fermer
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    // Ajout ou suppression de l'écouteur d'événement en fonction de l'état du modal
    if (show) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    // Nettoyage de l'écouteur d'événement lors du démontage du composant
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [show, onClose]);

  return (
    <BootstrapModal show={show} onHide={onClose} centered>
      <div ref={modalRef}>
        <BootstrapModal.Header>
          <BootstrapModal.Title>{title}</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          <div className="modal-content-custom">
            {/* Affichage de l'image du projet */}
            <img src={image} alt={title} className="modal-image" loading="lazy" />
            {/* Description du projet */}
            <p>{description}</p>
            <div className="modal-links">
              {/* Lien vers le projet */}
              <a href={url} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLink} /> Project Link
              </a>
              {/* Lien vers le dépôt GitHub */}
              <a href={github} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faGithub} /> GitHub Link
              </a>
            </div>
          </div>
        </BootstrapModal.Body>
        <BootstrapModal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </BootstrapModal.Footer>
      </div>
    </BootstrapModal>
  );
};

export default Modal;
