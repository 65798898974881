import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importation des styles Bootstrap
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Importation du JavaScript Bootstrap
import Home from '../pages/Home/Home'; // Importation du composant Home
import News from '../pages/News/News'; // Importation du composant News
import { meta, structuredData } from '../datas'; // Importation des données meta et structuredData
import './App.scss'; // Importation des styles spécifiques de l'application

// Fonction principale de l'application
function App() {
    return (
        <HelmetProvider>
            <div className="App">
                {/* Helmet pour définir les balises meta */}
                <Helmet>
                    <title>{meta.title}</title>
                    <meta name="description" content={meta.description} />
                    <meta name="keywords" content={meta.keywords} />
                    <meta name="robots" content={meta.robots} />
                    {/* Balises Open Graph */}
                    <meta property="og:title" content={meta.ogTitle} />
                    <meta
                        property="og:description"
                        content={meta.ogDescription}
                    />
                    <meta property="og:image" content={meta.ogImage} />
                    <meta property="og:url" content={meta.ogUrl} />
                    <meta property="og:type" content={meta.ogType} />
                    <meta property="og:locale" content={meta.ogLocale} />
                    {/* Balises Twitter */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={meta.title} />
                    <meta name="twitter:description" content={meta.description} />
                    <meta name="twitter:image" content={meta.ogImage} />
                    <link rel="canonical" href={meta.ogUrl} />
                    {/* Données structurées JSON-LD */}
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                </Helmet>
                {/* Rendu du composant Home */}
                <Home />
                {/* Rendu du composant News */}
                <News />
            </div>
        </HelmetProvider>
    );
}

export default App; // Exportation de la fonction App pour utilisation dans d'autres parties de l'application
