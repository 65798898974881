// Importation des modules nécessaires depuis React
import React from 'react';
// Importation des données de nouvelles depuis le fichier de données
import { dataNews } from '../../datas';
// Importation du composant Carousel
import Carousel from '../../components/Carousel/Carousel';
// Importation des styles spécifiques pour ce composant
import './News.scss';

// Définition du composant fonctionnel News
const News = () => {
    return (
        // Section "news" avec un identifiant pour l'ancrage
        <section className="news" id="news">
            <div className="max-width">
                {/* Titre de la section */}
                <h2 className="title">Fresh News</h2>
                <div className='underLine'></div>
                
                {/* Informations de nouvelles */}
                <div className="news-infos">
                    <h3>{dataNews.news}</h3>
                </div>
                
                {/* Carrousel des derniers projets */}
                <div className="news-carousel">
                    <Carousel />
                    <h4>Last Projects</h4>
                </div>
            </div>
        </section>
    );
};

// Exportation du composant pour l'utiliser dans d'autres parties de l'application
export default News;
