// Importation des modules nécessaires depuis React
import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// Importation des données de services depuis le fichier de données
import { skillData, otherSkillData, meta, structuredData } from '../../datas';
// Importation des styles spécifiques pour ce composant
import './Skills.scss';

const Skills = () => {
    return (
        <HelmetProvider>
        {/* Fragment React pour contenir les éléments du composant */}
        <>
            {/* Utilisation de Helmet pour définir les balises <head> */}
            <Helmet>
                <title>{meta.title} - Skills</title>
                <meta
                    name="description"
                    content={`Explore ${meta.title}'s services`}
                />
                <meta name="keywords" content={meta.keywords} />
                <meta name="robots" content={meta.robots} />
                {/* Balises Open Graph */}
                <meta property="og:title" content={meta.ogTitle} />
                <meta
                    property="og:description"
                    content={meta.ogDescription}
                />
                <meta property="og:image" content={meta.ogImage} />
                <meta property="og:url" content={meta.ogUrl} />
                <meta property="og:type" content={meta.ogType} />
                <meta property="og:locale" content={meta.ogLocale} />
                {/* Balises Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={meta.title} />
                <meta
                    name="twitter:description"
                    content={meta.description}
                />
                <meta name="twitter:image" content={meta.ogImage} />
                <link rel="canonical" href={meta.ogUrl} />
                {/* Données structurées JSON-LD */}
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
                {/* Section "services" avec un identifiant pour l'ancrage */}
                <section className="skills" id="skills">
            <div className="max-width">
                {/* Titre de la section */}
                <h2 className="title">Development Skills</h2>

                {/* Conteneur des compétences */}
                <div className="skills-content">
                    {skillData.map((skills) => {
                        return (
                            // Élément de compétence avec un identifiant unique
                            <div key={skills.id} className="skill-item my-md-5">
                                <figure>
                                    {/* Affichage de l'icône ou de l'image de la compétence */}
                                    <span aria-hidden="true">{skills.skill}</span>
                                    
                                    {/* Description textuelle de la compétence pour l'accessibilité */}
                                    <figcaption>{skills.name}</figcaption>
                                </figure>
                            </div>
                        );
                    })}
                </div>
                <h2 className="title otherTitle">Other Skills</h2>

                {/* Conteneur des compétences */}
                <div className="skills-content">
                    {otherSkillData.map((skills) => {
                        return (
                            // Élément de compétence avec un identifiant unique
                            <div key={skills.id} className="skill-item my-md-5">
                                <figure>
                                    {/* Affichage de l'icône ou de l'image de la compétence */}
                                    <span aria-hidden="true">{skills.skill}</span>
                                    
                                    {/* Description textuelle de la compétence pour l'accessibilité */}
                                    <figcaption>{skills.name}</figcaption>
                                </figure>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
        </>
        </HelmetProvider>
    );
};

// Exportation du composant pour l'utiliser dans d'autres parties de l'application
export default Skills;
