import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { meta, projectsData, structuredData } from '../../datas'; // Assurez-vous d'ajuster le chemin selon votre structure de fichiers
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

import './Projects.scss';

const Projects = () => {
    const sortedProjectsData = [...projectsData].sort((a, b) => b.id - a.id);

    return (
        <HelmetProvider>
            <>
                <Helmet>
                    <title>{meta.title} - Projects</title>
                    <meta
                        name="description"
                        content={`Explore ${meta.title}'s projects`}
                    />
                                        <meta name="keywords" content={meta.keywords} />
                    <meta name="robots" content={meta.robots} />
                    {/* Balises Open Graph */}
                    <meta property="og:title" content={meta.ogTitle} />
                    <meta
                        property="og:description"
                        content={meta.ogDescription}
                    />
                    <meta property="og:image" content={meta.ogImage} />
                    <meta property="og:url" content={meta.ogUrl} />
                    <meta property="og:type" content={meta.ogType} />
                    <meta property="og:locale" content={meta.ogLocale} />
                    {/* Balises Twitter */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={meta.title} />
                    <meta
                        name="twitter:description"
                        content={meta.description}
                    />
                    <meta name="twitter:image" content={meta.ogImage} />
                    <link rel="canonical" href={meta.ogUrl} />
                    {/* Données structurées JSON-LD */}
                    <script type="application/ld+json">
                        {JSON.stringify(structuredData)}
                    </script>
                </Helmet>

                <section className="projects" id="projects">
                    <div className="max-width">
                        <h2 className="title">Projects</h2>
                        <div className="projects-content-inner">
                            {sortedProjectsData.map((data, i) => (
                                <div key={i} className="project-item">
                                    <img
                                        src={data.image}
                                        alt={data.title || 'Project Image'}
                                        width={data.width} // Ajouter la largeur
                                        height={data.height} // Ajouter la hauteur
                                        loading="lazy"
                                    />
                                    <div className="project-content">
                                        <p>{data.description}</p>
                                        <div className="modal-links project-links">
                                            <a
                                                href={data.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label={`View ${data.title} project`}
                                            >
                                                <FontAwesomeIcon icon={faLink} />{' '}
                                                View Project
                                            </a>
                                            <a
                                                href={data.github}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                aria-label={`View ${data.title} on GitHub`}
                                            >
                                                <FontAwesomeIcon icon={faGithub} />{' '}
                                                View GitHub
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </>
        </HelmetProvider>
    );
};

export default Projects;
