// TypingEffect.js

// Importation des modules nécessaires depuis React
import React, { useEffect } from 'react';

// Importation de la bibliothèque Typed.js pour l'effet de frappe
import Typed from 'typed.js';

// Importation des styles spécifiques pour ce composant
import './TypingEffect.scss';

// Définition du composant fonctionnel TypingEffect
const TypingEffect = ({ strings, className }) => {
    // Utilisation d'un effet pour initialiser et nettoyer Typed.js
    useEffect(() => {
        // Options de configuration pour Typed.js
        const options = {
            strings: strings,
            typeSpeed: 100,  // Vitesse de frappe
            backSpeed: 60,   // Vitesse de suppression
            loop: true,      // Boucler l'animation
        };

        // Initialisation de Typed.js avec les options spécifiées
        const typed = new Typed(`.${className}`, options);

        // Nettoyage lors de la destruction du composant
        return () => {
            typed.destroy();
        };
    }, [strings, className]);

    // Rendu d'un élément span avec la classe spécifiée
    return <span className={className} aria-label="Typing effect text"></span>;
};

// Exportation du composant pour l'utiliser dans d'autres parties de l'application
export default TypingEffect;
